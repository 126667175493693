import React, { Component } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { mq } from "../utils/presets"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronSquareRight } from "@fortawesome/pro-light-svg-icons"
import { faChevronSquareLeft } from "@fortawesome/pro-light-svg-icons"
import parse from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import TopImage from "../components/TopImage"

const Wrapper = styled.div`
  ${mq.tablet} {
    max-width: 1080px;
    margin: 0 auto;
    margin-bottom: 3rem;
  }
`

const Textwrapper = styled.div`
  padding: 1rem;
  padding-bottom: 0.3rem;
  text-align: justify;
  ${mq.tablet} {
    margin-right: 1rem;
    h1 {
      text-align: left;
      border-bottom: none;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  ${mq.tablet} {
    margin-right: 0;
    padding-right: 1.5rem;
    padding-left: 0.7rem;
  }
`

const CustomGallery = styled.div`
  display: flex;
  flex-flow: row wrap;
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 31%;
    /* height: 250px; */
    object-fit: cover;
    margin: 1%;
    transition: all 0.3s;
    &:hover,
    &:focus {
      cursor: pointer;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    }
    &:focus {
      outline: 1px dotted grey;
      outline-offset: 5px;
    }
    div {
      width: 100%;
      margin: 0;
    }
    img {
      width: 100%;
    }
  }
`

const BottomNav = styled.nav`
  margin-top: 1rem;
  padding: 0.7rem;
  ${mq.tablet} {
    padding-right: 1.2rem;
  }
  a {
    text-decoration: none;
    display: flex;
    flex-flow: row;
  }
  ul {
    margin: 0;
    display: flex;
    flex-flow: row;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    li {
      svg {
        align-self: center;
      }
      width: 50%;
      flex: 1 0 auto;
      .first {
        margin-left: 0.6rem;
      }
      .second {
        text-align: right;
        margin-right: 0.6rem;
      }
    }
  }
`

const GalleryImage = styled.button`
  width: 31%;
  height: 120px;
  margin: 1%;
  ${mq.tablet} {
    height: 250px;
  }
  .gatsby-image-wrapper {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

export default class LightboxProjects extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state
    const { data } = this.props

    const projekt = data.wpProjekt
    const featuredImage = projekt?.featuredImage

    const images = projekt.projekte.galerie.map(
      (item) => item.localFile.childImageSharp.resize.src
    )

    const { previous, next } = this.props.pageContext

    const seo = projekt?.seo
    const seoTitle = projekt?.seo?.opengraphTitle || projekt.title || ``
    const metaDesc = seo.metaDesc || ``
    const seoImage =
      projekt?.featuredImage?.node.localFile.childImageSharp.resize

    return (
      <div>
        <Layout isFrontPage={false}>
          <Seo
            title={projekt.title}
            seoTitle={seoTitle}
            description={metaDesc}
            image={seoImage}
            uri={projekt.uri}
          />{" "}
          {!!featuredImage && <TopImage featuredImage={featuredImage} />}
          <Wrapper>
            <Textwrapper>
              {!!projekt?.title && <h1>{parse(projekt.title)}</h1>}
            </Textwrapper>

            <CustomGallery>
              {projekt.projekte.galerie.map((image, i) => {
                const galleryImage = {
                  image: getImage(image.localFile),
                  alt: image.altText || "",
                }

                return (
                  <GalleryImage
                    key={i}
                    onClick={() =>
                      this.setState({ isOpen: true, photoIndex: i })
                    }
                  >
                    <GatsbyImage
                      image={galleryImage.image}
                      alt={galleryImage.alt}
                    />
                  </GalleryImage>
                )
              })}
            </CustomGallery>

            <Textwrapper>
              {!!projekt?.content && <div>{parse(projekt.content)}</div>}
            </Textwrapper>

            <BottomNav>
              <ul>
                <li>
                  {previous && (
                    <Link to={`/projekte/${previous.slug}`} rel="prev">
                      <FontAwesomeIcon
                        size="lg"
                        className="faicon"
                        icon={faChevronSquareLeft}
                        style={{ marginRight: ".2rem" }}
                      />{" "}
                      <div
                        className="first"
                        dangerouslySetInnerHTML={{ __html: previous.title }}
                      />
                    </Link>
                  )}
                </li>
                <li>
                  {next && (
                    <Link
                      to={`/projekte/${next.slug}`}
                      rel="next"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <div
                        className="second"
                        dangerouslySetInnerHTML={{ __html: next.title }}
                      />{" "}
                      <FontAwesomeIcon
                        size="lg"
                        className="faicon"
                        icon={faChevronSquareRight}
                        style={{ marginLeft: ".2rem" }}
                      />
                    </Link>
                  )}
                </li>
              </ul>
            </BottomNav>
          </Wrapper>
        </Layout>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    )
  }
}

export const query = graphql`
  query ($id: String) {
    wpProjekt(id: { eq: $id }) {
      title
      content
      uri
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              resize(width: 1200, height: 627) {
                src
                tracedSVG
                width
                height
                aspectRatio
                originalName
              }
              gatsbyImageData(
                width: 2000
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphTitle
        opengraphDescription
        schema {
          articleType
          pageType
          raw
        }
      }
      projekte {
        galerie {
          altText
          localFile {
            url
            childImageSharp {
              resize(width: 2560) {
                src
              }
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
